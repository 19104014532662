import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BaseApp } from '@lalamove/karang';

import withLoadingBar from 'hoc/withLoadingBar';
import Home from 'containers/Home';
import Verification from 'containers/Verification';
import BasicInfo from 'containers/BasicInfo';
import Signup from 'containers/Signup'
import AdditionalInfo from 'containers/AdditionalInfo'
import Error from 'containers/Error';
import TrainingSession from 'containers/TrainingSession';
import TrainingSessionBooking from 'containers/TrainingSession/trainingSessionBooking';
import Welcome from 'containers/Welcome';
import CampaignDetails from 'containers/CampaignDetails';
import PrivateRoute from 'components/PrivateRoute';
import {CAMPAIGN_FEATURE, SENTRY_ENV} from 'startupScript';
import { isNewSignUpVersion } from 'utils/helpers';
import ChatWidget from './containers/ChatWidget';
import probes from '@hll/sec-sign-sdk'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
// 调试模式
// probes.init({debug: true});
//allowList 域名白名单
probes.init({
  debug: true,
  allowList: [
      'sg-uapi-stg.lalamove.com',
      'sg-uapi-pre.lalamove.com',
      'sg-uapi.lalamove.com',
      'br-uapi.lalamove.com',
      'uapi.bom.uncle-delivery.com'
  ],
});
Sentry.init({
    environment: SENTRY_ENV,
    // online one
    // dsn: "https://1033d706c88a43408076fe0e4b254435@o1020769.ingest.sentry.io/5986298",
    //self host one
    dsn: "https://f38e7cd975ad455ea8f70496cf669bf1@sg-sentry.lalamove.com/3",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});
const App = () => (
  <BaseApp>
    <div className="App">
      <Switch>
        <Route exact path="/register/:country/:lang" component={withLoadingBar( isNewSignUpVersion() ? Signup : Home)} />
        <PrivateRoute exact path="/register/verification" component={withLoadingBar(Verification)} />
        <PrivateRoute exact path="/register/basic-info" component={withLoadingBar(BasicInfo)} />
        <Route exact path="/register/additional-info" component={withLoadingBar(AdditionalInfo)} />
        <PrivateRoute exact path="/register/training-session" component={withLoadingBar(TrainingSession)} />
        {CAMPAIGN_FEATURE === 'true' && <Route exact path="/campaign/:id" component={withLoadingBar(CampaignDetails)} />}
        <Route exact path="/driver/training-session-booking" component={withLoadingBar(TrainingSessionBooking)} />
        <Route exact path="/register/error" component={withLoadingBar(Error)} />
        <Route exact path="/register/Welcome" component={withLoadingBar(Welcome)} />
        <Route exact path="/chat" component={withLoadingBar(ChatWidget)} />
      </Switch>
    </div>
  </BaseApp>
);

export default App;
