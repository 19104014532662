import {getParams} from './index';
import i18n from 'i18next';


const formField = {
    form: {
        autoSubmit: false
    },
    formJSON: {
        wrapper: '<table></table>'
    }
};

export function getAdvancedConfig() {
    const requiredCheck = (_, __, input) => (input && input.val() ? true : false);
    const paramsData = getParams();
    const { firstName, lastName, driverInfoLink, orderInfoLink, phoneNumber, queueName, question } = paramsData;
    let { form } = formField;
    const formInputField = [
        {
            id: 'cx_webchat_form_firstname',
            name: 'firstname',
            maxlength: '100',
            placeholder: i18n.t('Entrance.required'),
            label: i18n.t('Entrance.name'),
            readonly: (firstName || lastName) ? true : false,
            value: `${firstName || ''} ${lastName || ''}`.trim(),
            validate: requiredCheck
        },
        {
            id: 'custom_field_1',
            name: 'customField1',
            maxlength: '100',
            placeholder: 'Custom Data',
            label: 'custom_field_1',
            value: `${queueName && queueName.indexOf('USER') > -1 ? 'User Link / ID' : 'Driver Link / ID'}: ${driverInfoLink}`,
            type: "hidden"
        },
        {
            id: 'custom_field_2',
            name: 'customField2',
            maxlength: '100',
            placeholder: 'Custom Data',
            label: 'customField2',
            value: `Order Link / ID: ${orderInfoLink}`,
            type: "hidden"
        },
        {
            id: 'custom_field_3',
            name: 'customField3',
            maxlength: '400',
            placeholder: i18n.t('Entrance.required'),
            label: i18n.t('Entrance.question'),
            value: `${question || ''}`.trim(),
            type: "textarea",
            validate: requiredCheck
        }
    ];
    if(question&&question!==''&&((firstName&&firstName)!==''||(lastName&&lastName!==''))){
        form.autoSubmit = true;
    }
    const formData = {
        ...form,
        firstname: `${firstName || ''} ${lastName || ''}`.trim(),
        customField1: `${queueName && queueName.indexOf('USER') > -1 ? 'User Link / ID' : 'Driver Link / ID'}: ${driverInfoLink}`,
        customField2: `Order Link / ID: ${orderInfoLink}`,
        phoneNumber: phoneNumber,
    };
    return {
        form: formData,
        formJSON: {
            wrapper: '<table></table>',
            inputs: formInputField
        }
    };
};

export function listenCancelEvt(customPlugin){
    customPlugin.subscribe('WebChat.cancelled', function (e) {
        console.error('WebChat.cancelled: ', e);
        // End chat event send to device
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        setTimeout(() => {
            //Check mobile device is Android
            if (/android/i.test(userAgent)) {
                if (window.Android) {
                    window.Android.endChat();
                }
            }

            //Check mobile device is IOS
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                //Add your Code here
                var chatEvent = document.getElementById('eventTriger').href;
                window.open(chatEvent, '_self');
                // HLL style event
                if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.app && typeof window.webkit.messageHandlers.app.postMessage === 'function') {
                    window.webkit.messageHandlers.app.postMessage(JSON.stringify({action: "endChat"}));
                }
            }
        }, 2000);
    });
}

export function createWebchatObj(webchat, paramsData, isBotAvailable){
    let postParamsData = paramsData;
    if(!isBotAvailable){
        //for case: url dont have name, filled it in form, the portal cant get name, and display as Customer
        //for timeout box case, it will lead custom name missing
        delete postParamsData.firstName;
        delete postParamsData.lastName;
    }
    const webchatObj = {
      ...webchat, userData: {
        ...{
          customField3Label: i18n.t('Entrance.question'),
          ...postParamsData
        }
      }, form: getAdvancedConfig()
    };
    return webchatObj;
}

//----------- below part is the real questionform feature functions, hidden tmp
//
//
// import {getParams} from './index';
//
//
// const formField = {
//     form: {
//         autoSubmit: true,
//         firstname: '',
//         lastname: '',
//         email: '',
//         subject: 'Populated from data'
//     },
//     formJSON: {
//         wrapper: '<table></table>'
//     }
// };
//
// export function getAdvancedConfig() {
//     const paramsData = getParams();
//     const { firstName, lastName, email, driverInfoLink, orderInfoLink, phoneNumber, queueName } = paramsData;
//
//     const { form } = formField;
//     const formInputField = [
//         {
//             id: 'cx_webchat_form_firstname',
//             name: 'firstname',
//             maxlength: '100',
//             placeholder: 'First Name',
//             label: 'First Name',
//             value: firstName,
//             readonly: firstName ? true : false,
//         },
//         {
//             id: 'cx_webchat_form_lastname',
//             name: 'lastname',
//             maxlength: '100',
//             placeholder: 'Last Name',
//             label: 'Last Name',
//             value: lastName,
//             readonly: lastName ? true : false,
//         },
//         {
//             id: 'cx_webchat_form_email',
//             name: 'email',
//             maxlength: '100',
//             placeholder: 'Optional',
//             label: 'Email',
//             value: email,
//             readonly: email ? true : false,
//         },
//         {
//             id: 'cx_webchat_form_subject',
//             name: 'subject',
//             maxlength: '100',
//             placeholder: 'Optional',
//             label: 'Subject',
//             value: 'Web Chat'
//         },
//         {
//             id: 'custom_field_1',
//             name: 'customField1',
//             maxlength: '100',
//             placeholder: 'Custom Data',
//             label: 'custom_field_1',
//             value: `${queueName && queueName.indexOf('USER') > -1 ? 'User Link / ID' : 'Driver Link / ID'}: ${driverInfoLink}`,
//             type: "hidden"
//         },
//         {
//             id: 'custom_field_2',
//             name: 'customField2',
//             maxlength: '100',
//             placeholder: 'Custom Data',
//             label: 'customField2',
//             value: `Order Link / ID: ${orderInfoLink}`,
//             type: "hidden"
//         }
//     ];
//     const formData = {
//         ...form,
//         firstname: firstName,
//         lastname: lastName,
//         email: email,
//         customField1: `${queueName && queueName.indexOf('USER') > -1 ? 'User Link / ID' : 'Driver Link / ID'}: ${driverInfoLink}`,
//         customField2: `Order Link / ID: ${orderInfoLink}`,
//         phoneNumber: phoneNumber,
//     };
//     return {
//         form: formData,
//         formJSON: {
//             wrapper: '<table></table>',
//             inputs: formInputField
//         }
//     };
// };
//
// export function listenCancelEvt(customPlugin){
//     //old feat dont listen it
// }
//
// export function createWebchatObj(webchat, paramsData, isBotAvailable){
//     const webchatObj = { ...webchat, userData: paramsData, form: getAdvancedConfig() };
//     return webchatObj;
// }